export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "60px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Status",
    value: "status",
    width: "100px",
    order: 1,
    hidable: true,
    hidden: false,
  },
  {
    text: "Type",
    value: "templateType",
    width: "160px",
    order: 2,
    hidable: true,
    hidden: false,
  },
  { text: "File Name", value: "fileName", order: 3, hidable: false, hidden: false },
  {
    text: "Created By",
    value: "createdBy",
    width: "130px",
    order: 5,
    hidable: true,
    hidden: true,
  },
  {
    text: "Created",
    value: "createDate",
    width: "130px",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "130px",
    order: 6,
    hidable: true,
    hidden: false,
  },
];
